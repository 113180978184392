/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.customer-list-select.action-sheet-selected.sc-ion-action-sheet-ios {
  font-weight: normal;
}

.customer-list-select.action-sheet-selected.sc-ion-action-sheet-ios::after {
  background: none;
  opacity: 1;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  --button-color: black !important;
  font-size: 16px;
}
.action-sheet-selected.sc-ion-action-sheet-ios {
  --button-color-selected: black !important;
  font-size: 16px;
}

:root {
  --ion-color-dark: #0D2014;
}

.d-flex {
  display: flex;
}

// font styles
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fw-600 {
  font-weight: 600;
}

// bg color styles
.bg-dark {
  background-color: #0D2014;
}

// text color styles
.text-white {
  color: white;
}
.text-primary {
  color: #005e30;
}

// common styles
.logo {
  margin-left: 1.6rem;
  width: 4rem;
  height: 4rem;
  background-image: Url('https://jadcup.oss-cn-hongkong.aliyuncs.com/f8facef4-7757-4adc-99da-99aa90587355');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.slide-bg-img1 {
  background-image: Url("./assets/img01.webp");
}
.slide-bg-img2 {
  background-image: Url("./assets/img02.webp");
}
.slide-bg-img3 {
  background-image: Url("./assets/img03.webp");
}
.label {
  padding: 0.8rem;
}
.content {
  padding: 0.8rem;
}
.save-button {
  margin-top: 3rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.prod-img {
  // width: 5rem;
  // height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  min-height: 5rem;
  min-width: 5rem;
  margin-right: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: auto;
  }
}
